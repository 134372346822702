<template>
  <div class="number-stepper" :data-test-id="elementTest + '-number-stepper'">
    <div class="btn-wrapper" :data-test-id="elementTest + '-btn-wrapper'">
      <MainButton
        label=""
        tag="button"
        class-variant="btn-grey sp btn-radius-sm"
        class="number-stepper-left"
        :data-test-id="elementTest + '-minus-button'"
        :class="{ disabled: currentValue === minValue }"
        @click="setValue(currentValue - 1)"
      >
        <div class="icon-minus u-bg-dark-blue" />
      </MainButton>

      <div class="stepper-value-wrapper" @mouseleave="closeDropdown">
        <InputField
          ref="selectField"
          v-model.number="currentValue"
          input-type="number"
          :min-value="minValue"
          :max-value="maxValue"
          class="sp-input-field"
          :data-test-id="elementTest + '-input'"
          :element-test="elementTest + '-input'"
          @blur="setValue(currentValue)"
        />
        <div
          v-if="productStepper"
          class="stepper-value-wrapper-icon u-bg-steel-grey"
          :data-test-id="elementTest + '-select-icon'"
          :class="fieldFocus ? 'icon-up' : 'icon-down'"
          @click="showDropdown = !showDropdown"
        />
        <div
          v-if="showDropdown && productStepper"
          class="stepper-value-wrapper-dropdown"
          :data-test-id="elementTest + '-dropdown'"
          @mouseleave="showDropdown = !showDropdown"
        >
          <div
            v-for="(v, idx) in maxValue"
            :key="idx"
            class="stepper-value-wrapper-dropdown-item"
            :class="{ 'is-select': v === currentValue }"
            :data-test-id="elementTest + '-dropdown-item-' + v"
            @click="setValue(v)"
          >
            <div
              v-if="v === currentValue"
              class="stepper-value-wrapper-dropdown-item-icon icon-check u-bg-steel-grey"
            />
            {{ v }}
          </div>
        </div>
      </div>

      <MainButton
        label=""
        tag="button"
        class="number-stepper-right"
        class-variant="btn-grey sp btn-radius-sm"
        :data-test-id="elementTest + '-plus-button'"
        :class="{ disabled: currentValue === maxValue }"
        @click="setValue(currentValue + 1)"
      >
        <div class="icon-plus u-bg-dark-blue" />
      </MainButton>
    </div>
    <span
      v-if="tooMuchQuantities && productStepper"
      class="error too-much-quantities"
      :data-test-id="elementTest + '-too-much-quantities'"
      >{{ $t('message.tooMuchQuantities', maxValue) }}</span
    >
  </div>
</template>

<script setup>
import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputField from '@/components/InputField/InputField'

const props = defineProps({
  defaultValue: { type: Number, default: 1 },
  minValue: { type: Number, default: null },
  maxValue: { type: Number, default: null },
  productStepper: { type: Boolean, default: false },
  elementTest: { type: String, default: '' },
})

const emit = defineEmits(['on-value-change', 'update:modelValue'])
const currentValue = ref(props.defaultValue)
const showDropdown = ref(false)
const fieldFocus = ref(false)
const selectField = ref(null)

const tooMuchQuantities = computed(() => {
  return currentValue.value > props.maxValue
})

watch(
  () => props.maxValue,
  () => {
    setValue(currentValue.value)
  }
)

watch(
  () => props.defaultValue,
  newValue => {
    setValue(newValue)
  }
)

const closeDropdown = () => {
  if (showDropdown.value) {
    // selectField.value.manageBlur()
  }

  showDropdown.value = false
}

const setValue = v => {
  const isDigit = /^\d+$/.test(v) // Preventing e allowed in input type number
  isDigit
    ? (currentValue.value = parseInt(v))
    : props.minValue !== null
      ? (currentValue.value = props.minValue)
      : (currentValue.value = 1)
  if (props.maxValue !== null) {
    currentValue.value = Math.min(props.maxValue, currentValue.value)
  }
  if (props.minValue !== null) {
    currentValue.value = Math.max(props.minValue, currentValue.value)
  }
  onValueChange()
}

const onValueChange = () => {
  emit('update:modelValue', currentValue.value)
  emit('on-value-change', currentValue.value)
  closeDropdown()
}
</script>

<style lang="scss">
.card-wrapper {
  .number-stepper {
    justify-content: flex-end;
    .too-much-quantities {
      max-width: fit-content;
      flex-basis: 100%;
      margin: $spacing-sm 0 $spacing-sm $spacing-sm;
    }
  }
}

.number-stepper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .btn.disabled {
    opacity: 1;
    background-color: var(--bg-grey);
    svg {
      opacity: 0.2;
    }
  }

  .sp-select-icon {
    opacity: 0.3;
  }

  &:hover {
    .sp-select-icon {
      opacity: 1;
    }
  }

  .btn-wrapper {
    background-color: var(--light-bg-grey);
    border-radius: $spacing-sm;
  }

  .number-stepper-left.btn,
  .number-stepper-right.btn {
    margin: 0;
    padding: 0;
    width: 48px;
    height: 44px;

    &:hover {
      background-color: var(--light-grey);
      border-radius: $spacing-sm;
    }
  }

  .number-stepper-left.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 0s;
  }

  .number-stepper-right.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition: all 0s;
  }

  .stepper-value-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    .sp-input-field {
      margin: 0;
      .sp-input {
        max-width: 52px;
        background-color: var(--light-bg-grey);
        font-size: pxToRem(16px) !important;
        font-weight: 800;
        color: var(--black);
        padding-right: 0;
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .sp-select-icon {
      width: 100%;
    }

    &-icon {
      width: 40px;
      min-width: 30px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: 10px;
      right: 0;
      top: 0;
    }

    &-dropdown {
      position: absolute;
      top: 100%;
      background-color: var(--white);
      width: 100%;
      max-height: 226px;
      overflow-y: auto;
      padding: $spacing-sm 0;
      border-radius: $spacing-xs;
      box-shadow: 0px 5px 30px rgba(5, 23, 47, 0.15);
      z-index: 10;

      &-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        font-weight: 800;

        &:hover {
          background-color: var(--green-hover);
          cursor: pointer;
        }

        &.is-select {
          background-color: var(--green-hover);
        }

        &-icon {
          position: absolute;
          left: 0;
        }
      }
    }
  }
  .too-much-quantities {
    flex: 1 0 100%;
    margin: $spacing-sm 0;
  }
}
</style>
